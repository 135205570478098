import http from './axios';
import { API_VERSION_1_PREFIX } from '@/env';
//-------+---------+---------+---------+---------+---------+---------+---------E
// バックエンド mint-nft-backend DB テーブル top_banner からクリエイター情報を取得
// する。結果は一レコード一オブジェクトの配列。
export const getTop_banners = async () => {
  try {
    const response = await http.get(`${API_VERSION_1_PREFIX}/top_banner`
      // 2024/04/04現在、抽出条件なし、テーブル top_banner の全レコード取得。
      // , {
      //   params: {
      //     id: objectAboutTopBanner?.id,
      //   }
      // }
    );
    if (!response) throw 'No content found';
    return response;
  } catch (err) {
    return [];
  } // End of try {...} catch (...) {...}
}; // End of export const getTop_banners = async (objectAboutTopBanner) => {...}
export const addBanner = async (formData) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/top_banner`, formData);
  return data;
};

export const deleteBanner = async (bannerId) => {
  const data = await http.delete(`${API_VERSION_1_PREFIX}/top_banner/${bannerId}`);
  return data;
};
