<template>
  <div>
    <div v-if="userRole === 'admin'" class="create-project-page pb-8">
      <button v-if="!showSaveSection" class="bg-primary add-section hover:opacity-60 text-white font-medium h-8 flex px-4 rounded-md text-xl"
      @click="showSaveSection = true">追加</button>
      <div v-if="showSaveSection" class="save-section margin">
        <div id="app">
          <div>
            <label>PC Banner:
              <button @click="selectFile('pc')">選択</button>
              <input type="file" ref="pcFile" @change="handleFileChange('pc', $event)" style="display: none;">
              <span v-if="files.pc">{{ files.pc.name }}</span>
            </label>
          </div>
          <div>
            <label>Mobile Banner:
              <button @click="selectFile('mobile')">選択</button>
              <input type="file" ref="mobileFile" @change="handleFileChange('mobile', $event)" style="display: none;">
              <span v-if="files.mobile">{{ files.mobile.name }}</span>
            </label>
          </div>
        </div>
        <div>
          <label>Link: <input type="text" v-model="newBanner.link" class="link-input"></label>
        </div>
        <div class="w-full py-8">
          <button class="bg-primary margin text-white hover:opacity-60 text-2xl font-semibold py-2 px-12 rounded-xl"
          type="submit" :disabled="loading" @click="onSubmitForm">
            {{ $t('PROJECT_SAVE_BTN') }}
          </button>
        </div>
      </div>
      <div v-for="(banner, index) in top_banners" :key="banner.id" class="banner">
        <section>
          <h2>{{ '【バナー' + (index + 1) + '】' }}</h2>
          <p>PC Banner URL: <a :href="banner.pc_banner_url">{{ banner.pc_banner_url }}</a></p>
          <p>Mobile Banner URL: <a :href="banner.smartphone_banner_url">{{ banner.smartphone_banner_url }}</a></p>
          <p>リンク：<a :href="banner.link">{{ banner.link }}</a></p>
          <button @click="deleteProject(banner.id)">削除</button>
        </section>
      </div>
    </div>
    <div v-else class="mt-20 text-2xl font-bold text-center">
      {{ $t('PROJECT_INVALID_ROLE_NOTIFICATION') }}
    </div>
  </div>
</template>

<script>
import { getTop_banners,addBanner,deleteBanner } from '@/services/top_banner';
import { mapGetters } from 'vuex';
import UtilNotify from '@/utils/notify.util';
export default {
  data() {
    return {
      top_banners: [],
      showSaveSection: false,
      newBanner: {
        pc_banner_url: '',
        mobile_banner_url: '',
        link: ''
      },
      files: {
        pc: null,
        mobile: null
      },
      loading: false,
      bannerId:null,
    };
  },
  async mounted() {
    await this.getTop_banners();
  },
  computed: {
    ...mapGetters({
    userRole: 'wallet/loggedUserRole',
    account: 'wallet/account',
    }),
  },
  methods: {
    async getTop_banners() {
      try {
        this.top_banners = await getTop_banners();
      } catch(error) {
        alert(error);
      }
    },
    selectFile(deviceType) {
        this.$refs[`${deviceType}File`].click();
    },
    handleFileChange(deviceType, event) {
        this.files[deviceType] = event.target.files[0];
    },
    async onSubmitForm() {
      if (this.loading) return;
      this.loading = true;

      const payload = new FormData();
      // ファイルとその他のフォームデータを追加
      
      payload.append('banner', this.files.pc);
      payload.append('thumbnail', this.files.mobile);
      payload.append('link', this.newBanner.link); 

      try {
        const data = await addBanner(payload); 
        this.loading = false;
        if (data) {
            await this.getTop_banners(); // バナー一覧を再取得
            this.showSaveSection = false; // 保存セクションを閉じる
            UtilNotify.success(this.$notify, { message: '保存が成功しました' });
        }
      } catch (error) {
          this.loading = false;
          console.error(error);
          UtilNotify.error(this.$notify, error);
      }
    },
    async deleteProject(banner_id) {
      try {
        this.loading = true;
        await deleteBanner(banner_id);
        await this.getTop_banners(); // バナー一覧を再取得
        UtilNotify.success(this.$notify, { message: '削除しました' });
      } catch (error) {
        UtilNotify.error(this.$notify, error);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style
  lang="scss"
  scoped
>
.flex-start-align {
  display: flex;
  align-items: center; /* 垂直方向の中央揃えを保持 */
  justify-content: flex-start; /* 水平方向に左寄せ */
}
.bg-section-bg {
  display: flex;
  justify-content: center; /* 中央寄せに */
  align-items: center; /* 垂直中央寄せに */
}

button {
  position: relative; /* 絶対位置指定がされていないことを確認 */
  margin-top: 20px; /* 必要に応じて調整 */
}
body {
  color: #FFFFFF; /* テキストの色を白に変更 */
  background-color: #5D2555; /* 背景色を濃い紫に設定 */
  font-family: 'Arial', sans-serif; /* フォントスタイル */
}

input, button, select {
  color: #333; /* テキスト色を暗い色に */
  background-color: #F5F5F5; /* 背景色を明るい灰色に */
  border: none; /* ボーダーを非表示に */
  padding: 10px 15px; /* パディングを調整 */
  border-radius: 4px; /* 角を丸く */
}

input::placeholder {
  color: #999; /* プレースホルダーの色を暗めの灰色に */
}

button {
  color: white;
  background-color: #9C27B0; /* ボタンの背景色を明るい紫に */
  font-weight: bold;
  cursor: pointer; /* カーソルをポインターに */
  transition: background-color 0.3s; /* 背景色の変化を滑らかに */
}

button:hover {
  background-color: #AB47BC; /* ホバー時の背景色を変更 */
  opacity: 0.9;
}

a {
  color: #FFD700; /* リンクの色をゴールドに */
  text-decoration: none; /* 下線を消す */
}

a:hover {
  text-decoration: underline; /* ホバー時に下線を表示 */
}

.banner {
  background-color: #673AB7; /* バナーの背景色を紫に */
  color: #FFFFFF; /* バナーのテキスト色を白に */
  padding: 15px;
  margin-bottom: 20px; /* バナー間のマージン */
  border-radius: 8px; /* 角を丸くする */
}
.margin{
  margin-bottom:20px;
  margin-left:10px;
}
.add-section {
  padding-bottom: 35px;
  margin-bottom:20px;
  margin-left:10px;
}

</style>